export default {
    methods: {
        scrollValidation() {
            const el = this.$el.querySelector(
                ".v-messages.error--text:first-of-type"
            );
            window.scrollTo({
                top: el.closest(".v-input__control").getBoundingClientRect().top -
                    76 +
                    window.scrollY,
                behavior: "smooth",
            });
        }
    },
}