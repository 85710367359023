<template>
  <div class="col-md-12">
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <section>
            <h3>Search By Phone No</h3>
            <ValidationObserver
              ref="searchFormObserver"
              v-slot="{ handleSubmit }"
            >
              <v-form
                ref="searchForm"
                class="pr-md-12 mt-4"
                @submit.prevent="handleSubmit(submitSearchInfo)"
              >
                <v-row class="position-relative">
                  <v-col
                    cols="12"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      vid="search_term"
                      name="Phone No"
                      rules="required"
                    >
                      <v-text-field
                        v-model="searchByPhoneNo.keyword"
                        name="search_term"
                        outlined
                        dense
                        flat
                        label="Phone No"
                        maxlength="11"
                        type="text"
                        color="primary-color"
                        :error-messages="errors"
                        class="search-patient-phone"
                        append-icon="mdi-magnify"
                        @click:append="onClickPatientSearchIcon"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </section>

          <section>
            <h3>Create New Appointment</h3>
            <ValidationObserver
              ref="appointmentFormObserver"
              v-slot="{ handleSubmit, reset }"
            >
              <v-form
                ref="appointmentForm"
                class="pr-md-12 mt-4"
                @submit.prevent="handleSubmit(submitNewUserInfo)"
                @reset.prevent="reset"
              >
                <v-row align="center">
                  <v-col cols="12">
                    <ValidationProvider
                      v-slot="{errors}"
                      vid="patient_full_name"
                      rules="required"
                      name="Patient Full Name"
                    >
                      <v-text-field
                        v-model="formData.name"
                        hide-details="auto"
                        outlined
                        dense
                        label="Patient Full Name"
                        maxlength="60"
                        class="mb-4"
                        color="primary-color"
                        name="patient_full_name"
                        :error-messages="errors"
                      />
                    </ValidationProvider>

                    <ValidationProvider
                      v-slot="{errors}"
                      vid="phone_number"
                      name="Phone No"
                      rules="required"
                    >
                      <v-text-field
                        v-model="formData.phoneNo"
                        hide-details="auto"
                        outlined
                        dense
                        label="Phone No"
                        maxlength="11"
                        color="primary-color"
                        :error-messages="errors"
                        class="mb-4"
                      />
                    </ValidationProvider>

                    <v-container class="mb-4">
                      <v-row>
                        <v-col
                          cols="6"
                          class="pa-0 pr-2"
                        >
                          <v-menu
                            ref="dateOfBirth"
                            v-model="dateOfBirth"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <ValidationProvider
                                v-slot="{errors}"
                                vid="dob"
                                name="Date of Birth"
                                rules="required"
                              >
                                <v-text-field
                                  v-model="date_of_birth"
                                  name="dob"
                                  label="Date of Birth"
                                  placeholder="DD-MM-YYYY"
                                  outlined
                                  dense
                                  color="primary-color"
                                  hide-details="auto"
                                  :error-messages="errors"
                                  @change="patientDateOfBirth"
                                >
                                  <template #append>
                                    <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      mdi-calendar
                                    </v-icon>
                                  </template>
                                </v-text-field>
                              </ValidationProvider>
                            </template>
                            <v-date-picker
                              v-model="formData.dob"
                              no-title
                              @change="dateOfBirthFromDatePicker"
                              @input="dateOfBirth = false"
                            />
                          </v-menu>
                        </v-col>

                        <v-col
                          cols="6"
                          class="pa-0 pl-2"
                        >
                          <ValidationProvider
                            v-slot="{errors}"
                            vid="select_gender"
                            name="Select Gender"
                            rules="required"
                          >
                            <v-select
                              v-model="formData.gender"
                              outlined
                              dense
                              name="select_gender"
                              hide-details="auto"
                              :items="genders"
                              label="Select Gender"
                              color="primary-color"
                              required
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-container class="mb-4">
                      <v-row>
                        <v-col
                          cols="6"
                          class="pa-0 pr-2"
                        >
                          <v-radio-group
                            v-model="formData.idType"
                            mandatory
                            row
                            class="mt-0 d-flex align-center"
                            color="primary-color"
                          >
                            <v-radio
                              label="NID"
                              value="nid"
                            />

                            <v-radio
                              label="Birth Certificate"
                              value="bc"
                            />
                          </v-radio-group>
                        </v-col>

                        <v-col
                          cols="6"
                          class="pa-0 pl-2"
                        >
                          <ValidationProvider
                            v-slot="{errors}"
                            vid="nid"
                            name="NID/Birth Certificate Number"
                            rules="required"
                          >
                            <v-text-field
                              v-model="formData.nid"
                              name="nid"
                              hide-details="auto"
                              outlined
                              dense
                              label="NID/Birth Certificate Number"
                              color="primary-color"
                              required
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-container>

                    <div class="mb-4">
                      <v-menu
                        ref="scheduledAt"
                        v-model="scheduledAt"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template #activator="{ on, attrs }">
                          <ValidationProvider
                            v-slot="{errors}"
                            vid="schedule_at"
                            name="Schedule at"
                            rules="required"
                          >
                            <v-text-field
                              v-model="scheduled_date"
                              name="schedule_at"
                              label="Schedule at"
                              placeholder="DD-MM-YYYY"
                              outlined
                              dense
                              hide-details="auto"
                              color="primary-color"
                              :error-messages="errors"
                              @change="scheduledDate"
                            >
                              <template #append>
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-calendar
                                </v-icon>
                              </template>
                            </v-text-field>
                          </ValidationProvider>
                        </template>
                        <v-date-picker
                          v-model="formData.scheduledAt"
                          no-title
                          @change="scheduledDateFromDatePicker"
                          @input="scheduledAt = false"
                        />
                      </v-menu>
                    </div>

                    <ValidationProvider
                      v-slot="{errors}"
                      vid="location"
                      name="Location"
                      rules="required"
                    >
                      <v-text-field
                        v-model="formData.location"
                        name="location"
                        hide-details="auto"
                        outlined
                        dense
                        label="Location"
                        color="primary-color"
                        required
                        :error-messages="errors"
                        type="text"
                      />
                    </ValidationProvider>
                  </v-col>

                  <v-col
                    cols="12"
                    class="d-flex justify-end"
                  >
                    <v-btn
                      class="mr-4 text-capitalize font-weight-bold"
                      color="primary-color"
                      outlined
                      type="reset"
                      @click.prevent="cancel"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      type="submit"
                      class="text-capitalize font-weight-bold"
                      color="orange darken-3" 
                      outlined
                    >
                      Confirm
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </section>
        </v-col>
        <!-- <v-col
          cols="12"
          md="2"
          class="take-photo-ocr-btn"
        >
          <div class="take-photo-img-parent">
            <PhotoModal />
          </div>

          <div class="orc-img-parent">
            <OcrModal ref="ocrImage" />
          </div>
        </v-col> -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {fireNotification,formatDate,displayFormat} from "@/helpers/utility";
import scrollErrorValidationMixin from "@/mixins/scrollErrorValidationMixin";
import {ValidationObserver, ValidationProvider, extend} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';
import moment, { now } from "moment";
// import OcrModal from "@/components/OcrModal";
// import PhotoModal from "@/components/TakePhoto";

extend('required', {
    ...required,
    message: "The {_field_} field is required."
});


export default {
    name: "NewAppointment",
    components: {ValidationObserver, ValidationProvider},
    mixins: [scrollErrorValidationMixin],
    data: () => ({
        dateOfBirth: false,
        scheduledAt: false,
        searchByPhoneNo: {
            keyword: null
        },
        loading: false,
        searchInfo: '',
        formData: {
            name: "",
            phoneNo: "",
            nid: "",
            idType: "",
            gender: "",
            dob: null,
            patientId: 0,
            docId: null,
            scheduledAt: null,
            location: ""
        },
        genders: [
            'Male',
            'Female',
            'Other',
        ],
        scheduled_date: '',
        date_of_birth: ''
    }),
    methods: {
        onClickPatientSearchIcon() {
            this.$refs.searchFormObserver.validate()
                .then((isValidate) => {
                    if (isValidate) {
                        this.submitSearchInfo();
                    }
                });
        },
        async submitSearchInfo() {
            try {
                this.loading = true;
                this.cancel();
                let response = await this.$store.dispatch('appointments/search', this.searchByPhoneNo);
                if (response.data.Message === "Match Found" || response.status === 200) {
                    this.searchInfo = response.data.Data;
                    this.onSearchSuccessful(response.data.Message);
                } else {
                    throw "Not Found";
                }
            } catch (exception) {
                this.searchInfo = '';
                this.$nextTick(() => {
                    this.$refs.searchFormObserver.reset();
                });
            }
        },
        cancel() {
            this.$nextTick(() => {
                this.$refs.appointmentFormObserver.reset();
                this.$refs.appointmentForm.reset();
            });
        },
        successResponse(response) {
            fireNotification({
                action: true,
                text: response.data.Message,
                color: "primary"
            });
            this.cancel();
            setTimeout(() => {
                this.loading = false;
            }, 500);
        },
        errorResponse(error) {
            this.loading = false;
            fireNotification({
                action: true,
                text: error.response.data.Message,
                color: "error"
            });
        },
        async submitNewUserInfo() {
            try {
                this.formData.docId = this.$store.getters["authStore/authData"].user_id;
                let response = await this.$store.dispatch('appointments/store', this.formData);
                this.successResponse(response);
            } catch (exception) {
                this.errorResponse(exception);
            }
        },
        scheduledDate(date) {
            this.formData.scheduledAt = formatDate(date);
        },
        scheduledDateFromDatePicker(date) {
            this.scheduled_date = displayFormat(date);
        },
        patientDateOfBirth(date) {
            this.formData.dob = formatDate(date);
        },
        dateOfBirthFromDatePicker(date) {
            this.date_of_birth = displayFormat(date);
        },
        onClickTakePhoto() {
            if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
                console.log("Let's get this party started");
            } else {
                fireNotification({
                    action: true,
                    text: 'Your browser does not support this functionality.',
                    color: "error"
                });
            }
        },
        onSearchSuccessful(message) {
            this.formData = {
                name: this.searchInfo.name,
                phoneNo: this.searchInfo.phoneNo,
                nid: this.searchInfo.nid,
                idType: this.searchInfo.idType,
                gender: this.searchInfo.gender,
                dob: moment(this.searchInfo.dob).format('YYYY-MM-DD'),
                patientId: this.searchInfo.id,
                docId: this.$store.getters["authStore/authData"].user_id,
                scheduledAt: moment(now()).format('YYYY-MM-DD'),
                location: ""
            };
            this.date_of_birth = moment(this.searchInfo.dob).format('YYYY-MM-DD');
            this.scheduled_date = moment(now()).format('YYYY-MM-DD');

            fireNotification({
                action: true,
                text: message,
                color: "orange"
            });
        }
    }
}
</script>

<style scoped lang="scss">
ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

.search-phone input {
    padding-right: 64px;
}

.v-text-field__details {
    padding: 0 !important;
}

.take-photo-img-parent,
.orc-img-parent {
    text-align: center;
    cursor: pointer;
}
.take-photo-img-parent {
    margin-bottom: 16px;
}

/* screen 768px or less */
@media (max-width: 768px) {
    .take-photo-ocr-btn{
        display: flex !important;
    }
    .take-photo-image,
    .ocr-image {
        width: 30% !important;
    }
    .take-photo-heading,
    .ocr-heading {
        text-align: left;
        font-size: 15px;
    }
    .take-photo-img-parent,
    .orc-img-parent {
        text-align: left;
    }
    .take-photo-img-parent {
        margin-bottom: 0;
    }
}
</style>